import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "17",
  viewBox: "0 0 16 17",
  fill: "#009D2F",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "8",
      cy: "8.5",
      r: "4",
      fill: "inherit"
    }, null, -1),
    _createElementVNode("circle", {
      opacity: "0.2",
      cx: "8",
      cy: "8.5",
      r: "8",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }